export default {
	areWelcome: "son bienvenidas.",
	by: "por",
	contributions: "Contribuciones",
	like: "me gusta",
	love: "amor",
	madeWith: "Hecho con",
	pageDescription:
		"Todos los sociales y las mejores clases de bachata y clases de salsa cerca de ti. ¡Deja de buscar en múltiples grupos y encuentra aquí el social de SBK para hoy con un solo clic!",
	pageKeywords:
		"clases de bachata, clases de salsa, academias de baile cerca de mí, clases de baile cerca de mi, clases de bachata cerca de mi, academia baile cerca de mi, clase de baile cerca de mi, fiestas de salsa, fiestas de bachata",
	pageTitle: "Sociales y Clases de Bachata y Salsa Cerca de Ti | SBK Hoy",
	salsaBachataKizomba: "Salsa Bachata Kizomba",
	salsaBachataKizombaSocials: "Sociales de Salsa Bachata Kizomba",
	socials: "Sociales",
	thisIsAnOpenSourceProject: "Este es un proyecto open-source.",
	eventName: "Nombre del evento",
	eventInformationUrl: "Link de información del evento",
	date: "Fecha",
	startTime: "Inicio",
	endTime: "Fin",
	location: "Ubicación",
	locationGoogleMapsUrl: "Enlace de Google Maps",
	organizer: "Organizador",
	salsaPercentage: "Porcentaje de salsa",
	bachataPercentage: "Porcentaje de bachata",
	kizombaPercentage: "Porcentaje de kizomba",
	createEvent: "Crear evento",
	wrongValueType: "Valor incorrecto",
	valueMustBeBetween: "El valor debe estar entre {{min}} y {{max}}",
	wrongUrl: "Enlace incorrecto",
	mandatoryField: "Campo obligatorio",
	socialMediaPosterEtc: "Cartel de redes sociales, grupo de WhatsApp, etc.",
	totalMustBe100: "El total debe ser 100",
	publishEvent: "Publicar evento",
	close: "Cerrar",
	eventCreated: "¡Evento creado!",
	eventSuccessfullyCreatedInfo:
		"¡Gracias por enviar el evento! Normalmente revisamos las solicitudes en un plazo de dos horas y garantizamos una respuesta dentro de las 24 horas. ¡Pronto verás tu evento online!",
	allTheEventsAndClasses:
		"¿Conoces algún evento que no está aquí? ¡[Agrégalo](/events/create) así no seas el organizador!\n¡Ayuda a que todos sepamos dónde bailar hoy!",
};
